import { get } from '../../../utils/api';
import {getParentEntityAwarePath} from '../../../utils/entity';
import { generateIdSerializer } from '../../../utils/serializers';

import DefaultList from '../List';

export default class ConnectivityHistoryList extends DefaultList {

    list() {
        return this.fetchHistory();
    }

    fetchHistory = this.fetchHistory.bind(this)
    async fetchHistory() {
        const entityId = this.props.parentEntity.entityId;

        let startTime;
        let endTime;

        if (this.state.filterQueryParams && this.state.filterQueryParams.createTimeStart) {
            startTime = this.state.filterQueryParams.createTimeStart;
        } else {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 30); // by default last 30 days of history will be fetched
            startTime = currentDate.toISOString();
        }

        if (this.state.filterQueryParams && this.state.filterQueryParams.createTimeEnd) {
            endTime = this.state.filterQueryParams.createTimeEnd;
        } else {
            endTime = new Date().toISOString();
        }

        const startTimeAsDate = new Date(startTime);
        const endTimeAsDate = new Date(endTime);

        const differenceInMs = endTimeAsDate - startTimeAsDate;

        // Convert milliseconds to days
        const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

        if (differenceInDays > 30) {
            throw new Error("The difference between start and end time cannot exceed 30 days.");
        }

        return get(`/v1alpha/troubleshoot/connectivity-history/${entityId}?startTime=${startTime}&endTime=${endTime}`)
        .then(resp => resp, (err) => {
            if (err.data.code === 404) {
                //404s will be handled as a "No data response"
                return err;
            }
            else {
                this.setState({ errorStatusCode: err.data.code, errorStatusMessage: err.data.message });
                throw err;
            }
        });
    }

    onListSuccess = this.onListSuccess.bind(this);
    onListSuccess(resp) {
        const { parentEntity, actions, entityAlias } = this.props;

        let history = resp.data?.device_connectivity_history?.map((obj, index) => {
                return ({'_id': generateIdSerializer([obj.connectivity_state_time, obj.connectivity_source, obj.connectivity_state, index]),
                        'time' : obj.connectivity_state_time, 'status': obj.connectivity_state,
                        'connectivitySource':  obj.connectivity_source,
                        'disconnectReason': obj.disconnect_reason});
        }).flatMap(obj => obj);

        const path = [
        ...getParentEntityAwarePath(parentEntity, entityAlias),
        'connectivityHistory'
        ];

        if (history === undefined) {
            history = []
        }

        actions.listEntitiesSuccess({
        path,
        data: history,
        pkField: '_id',
        replace: true
        });
    }
}