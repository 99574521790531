import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import upperFirst from 'lodash/upperFirst';

import AuInput from '@au/core/lib/components/elements/AuInput';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';

import { tapFilterType, deviceType, SERVICE_NAMES } from '../constants';
import { required } from '../utils/validationRules';
import AttributeTagFilterValue from './AttributeTagFilterValue';
import MembershipTagFilterValue from './MembershipTagFilterValue';
import GroupFilterValue from './GroupFilterValue';
import VinEditor from './VinEditor';
import { TagFilterKey } from "./TagFilterKey";
import { AnnotationFilterKey } from "./AnnotationFilterKey";
import AnnotationFilterValue from './AnnotationFilterValue';

import styles from '../css/components/tap_filter_details_editor.module.scss';

const knownFilterTypes = Object.values(tapFilterType);
const deviceTypeOptions = Object.values(deviceType).map(type => {
  return {val: type, displayId: `au.devices.types.${type}`};
});

export const filterFieldsMap = {
  [tapFilterType.IAM]: [
    {
      name: 'subject',
      source: {
        service: SERVICE_NAMES.ACCOUNTS,
        entity: 'clients',
      },
      labelId: 'au.taps.client',
      component: AuDropDown,
      props: { allowTyping: true },
      map: {
        display: 'name',
        value: 'subjectAui',
      },
      rules: [required],
      options: [],
      default: ''
    },
    {
      name: 'role',
      source: {
        service: SERVICE_NAMES.IAM,
        entity: 'roles',
      },
      labelId: 'au.taps.role',
      component: AuDropDown,
      props: { allowTyping: true },
      map: {
        display: 'name',
        value: (resource) => `aui:iam:role/${resource.get('name')}`
      },
      rules: [required],
      options: [],
      default: ''
    }
  ],
  [tapFilterType.ATTRIBUTE_TAG]: [
    {
      name: 'tagKey',
      labelId: 'au.taps.attributeKey',
      component: TagFilterKey,
      props: { tagType: "Attribute" },
      rules: [required]
    },
    {
      name: 'tagValue',
      labelId: 'au.taps.attributeValue',
      component: AttributeTagFilterValue,
      props: { hintClassName: styles.hint },
      rules: [required]
    }
  ],
  [tapFilterType.GROUP]: [
    {
      name: 'groupId',
      component: GroupFilterValue,
      rules: [required],
      default: ''
    }
  ],
  [tapFilterType.DEVICE]: [
    {
      name: 'deviceType',
      labelId: 'au.taps.deviceType',
      component: AuDropDown,
      rules: [required],
      options: deviceTypeOptions,
      default: ''
    },
  ],
  [tapFilterType.ATTRIBUTE]: [
    {
      name: 'name',
      default: 'uri',
      labelClassName: styles.attribute_uri,
    },
    {
      name: 'value',
      labelId: 'au.taps.attributeUri',
      component: AuInput,
      rules: [required],
      default: ''
    },
  ],
  [tapFilterType.MEMBERSHIP_TAG]: [
    {
      name: 'tagKey',
      labelId: 'au.taps.tagKey',
      component: TagFilterKey,
      props: { tagType: "Membership" },
      rules: [required],
      default: 'businessUnit'
    },
    {
      name: 'tagValue',
      labelId: 'au.taps.tagValue',
      component: MembershipTagFilterValue,
      props: { selectionClassName: styles.selection },
      rules: [required]
    },
  ],
  [tapFilterType.VIN]: [
    {
      name: 'vins',
      labelId: 'au.taps.vins',
      component: VinEditor,
      rules: [required],
      default: []
    }
  ],
  [tapFilterType.MEMBER_PASS_THROUGH]: [
    {
      labelId: 'au.taps.configurationNotNecessary',
      labelClassName: styles.passthrough_text
    }
  ],
  [tapFilterType.SIGNAL_PASS_THROUGH]: [
    {
      labelId: 'au.taps.configurationNotNecessary',
      labelClassName: styles.passthrough_text
    }
  ],
  [tapFilterType.TAG]: [
    {
      name: 'tagKey',
      labelId: 'au.taps.tagKey',
      component: AuDropDown,
      rules: [required],
      options: [{ val: 'REPORT_TYPE', displayString: 'REPORT_TYPE' }],
      default: 'REPORT_TYPE'
    },
    {
      name: 'tagValue',
      labelId: 'au.taps.tagValue',
      component: AuDropDown,
      rules: [required],
      options: [
        { val: 'ANTI_THEFT',        displayString: 'ANTI_THEFT' },
        { val: 'DEVICE_PROPERTIES', displayString: 'DEVICE_PROPERTIES' },
        { val: 'VEHICLE_HEALTH',    displayString: 'VEHICLE_HEALTH' },
        { val: 'VEHICLE_STATUS',    displayString: 'VEHICLE_STATUS' },
      ],
      default: ''
    },
  ],
  [tapFilterType.ANNOTATION]: [
    {
      name: 'annotationFilterKey',
      labelId: 'au.taps.filter.details.annotation',
      component: AnnotationFilterKey,
      props: { hintClassName: styles.hint },
      rules: [required],
      default: 'FTCP_MESSAGE_NAME'
    },
    {
      name: 'annotationFilterValue',
      labelId: 'au.taps.attributeValue',
      component: AnnotationFilterValue,
      props: { hintClassName: styles.hint },
      rules: [required],
      default: [],
      labelClassName: styles.annotation_label
    }
  ]
};

export default class TapFilterDetailsEditor extends React.Component {

  static propTypes = {
    filterType: T.oneOf(knownFilterTypes),
    details: T.object,
    resources: T.instanceOf(Map),
    createMode: T.bool,
    onChange: T.func
  }

  static defaultProps = {
    details: {},
    resources: new Map()
  }

  _fieldOptions = {};

  constructor(props) {
    super(props);

    const filterType = props.filterType && knownFilterTypes.includes(props.filterType) ? props.filterType : undefined;
    const filterData = props.details ?? {};

    this.fields = filterFieldsMap[filterType] || [];
    this.state = this.fields.reduce((acc, field) => {
      if (!field.name) return acc;
      // using aliases to avoid duplicate field names in the state
      const fieldAlias = filterType + upperFirst(field.name);
      acc[fieldAlias] = filterData[field.name] || field.default;
      if (field.extraProps) {
        field.extraProps.forEach(propName => {
          let propAlias = filterType + upperFirst(propName);
          acc[propAlias] = filterData[propName];
        });
      }

      if (filterType == 'AnnotationFilter' && acc.AnnotationFilterAnnotationFilterKey !== 'annotationFilterKey' && Object.keys(filterData).length !== 0) {
        let values = [];
        acc.AnnotationFilterAnnotationFilterKey = Object.keys(props.details)[0];
        let idx = 0;
        while (idx < Object.values(props.details).lastItem?.length) {
          Object.values(props.details)?.forEach(value => values.push({value: value[idx], idx: idx}))
          idx++;
        }
        acc.AnnotationFilterAnnotationFilterValue = values;

      }
      return acc;
    }, {});

    // Propagate the default state to the caller.
    this.commitChanges(this.state);
  }

  onFieldChange = this.onFieldChange.bind(this);
  onFieldChange(field, value) {
    this.setState({ [field]: value }, this.commitChanges);
  }

  commitChanges() {
    const fieldValues = this.getFieldValues();
    this.props.onChange(fieldValues);
  }

  getFieldValues() {
    const { filterType } = this.props;
    const fieldValues = {...this.state};

    // Replace aliases with actual field names
    for (let field of this.fields) {
      const fieldAlias = filterType + upperFirst(field.name);
      if (fieldAlias in fieldValues) {
        fieldValues[field.name] = fieldValues[fieldAlias];
        delete fieldValues[fieldAlias];
      }
      if (field.extraProps) {
        field.extraProps.forEach(propName => {
          let propAlias = filterType + upperFirst(propName);
          if (propAlias in fieldValues) {
            fieldValues[propName] = fieldValues[propAlias];
            delete fieldValues[propAlias];
          }
        });
      }
    }

    if (filterType == 'AnnotationFilter') {
      let values = [];
      fieldValues.annotationFilterValue?.forEach(item => values.push(item.value.trim()));
      fieldValues['FTCP_MESSAGE_NAME'] = values;
      delete fieldValues.annotationFilterKey;
      delete fieldValues.annotationFilterValue;
    }

    return fieldValues;
  }

  buildOptions(field) {
    const { resources } = this.props;

    if (Array.isArray(field.options) && field.options.length) {
      return field.options;
    }

    let path;
    let options = [];
    const { map, source } = field;

    if (source) {
      path = `${source.service}-${source.entity}`;
    }

    if (path && this._fieldOptions[path]) {
      return this._fieldOptions[path];
    }

    if (map && path && resources.has(path)) {
      const getValue = typeof map.value === 'function' ? map.value : res => res.get(map.value);
      options = resources.get(path).map(resource => ({
        displayString: resource.get(map.display),
        val: getValue(resource)
      })).filter(option => option.val).toJS();

      this._fieldOptions[path] = options;
    }

    return options;
  }

  render() {
    const { filterType, createMode } = this.props;

    if (!this.fields.length) {
      return false;
    }

    return (
      <React.Fragment>
        { this.fields.map(field => {
          const Component = field.component;
          // using aliases to avoid duplicate field names in the state
          const fieldAlias = filterType + upperFirst(field.name);
          const fieldValue = this.state[fieldAlias];

          const props = {
            ...field.props,
            ...this.getFieldValues(),
            className: styles.input,
            createMode
          };

          if (field.component === AuInput) {
            props.name = fieldAlias;
            props.value = fieldValue;
            props.onChange = (e) => this.onFieldChange(fieldAlias, e.target.value);
            props.onClear = () => this.onFieldChange(fieldAlias, '');
          } else if (field.component === AuDropDown) {
            props.selection = fieldValue;
            props.selectionClassName = styles.selection;
            props.options = this.buildOptions(field);
            props.selectOption = val => this.onFieldChange(fieldAlias, val);
            props.onChange = val => this.onFieldChange(fieldAlias, val);
          } else if (field.component === VinEditor) {
            props.vins = fieldValue;
            props.onChange = val => this.onFieldChange(fieldAlias, val);
          } else {
            props.value = fieldValue;
            props.onChange = (val, name) => {
              this.onFieldChange(name ? filterType + upperFirst(name) : fieldAlias, val);
            };
          }

          return (
            <div key={`field_${filterType}_${fieldAlias}`} className={cn(styles.control, {[styles.remove_padding]: filterType === 'attributeFilter'})}>
              { field.labelId &&
                <AutoIntl displayId={field.labelId} className={cn(styles.label, field.labelClassName)}/>
              }
              { field.component && <Component {...props} /> }
            </div>
          );
        })}
      </React.Fragment>
    );
  }

}
