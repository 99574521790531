import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import Immutable from 'immutable';
import cn from "classnames";

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import SearchableTooltip from './SearchableTooltip'; 
import { formatMessage } from '../utils/reactIntl';

import styles from '../css/components/fancy_key_value.module.scss';

export default class FancyKeyValue extends React.PureComponent {
  static propTypes = {
    value: T.oneOfType([IPT.map, T.object]),
    hideKey: T.bool,
    limit: T.number
  }

  static defaultProps = {
    hideKey: false,
    limit: 8
  }

  state = { position: ''};

  setDirection = this.setDirection.bind(this);
  setDirection() {
    if (this.extraRef) {
      if (window.innerHeight - this.extraRef.getBoundingClientRect().bottom < 241 /*tooltip maxHeight and footer */) {
        this.setState({ position: 'above' });
      } else if (this.state.position != 'below') {
        this.setState({ position: 'below' });
      }
    }
  }

  setRef = this.setRef.bind(this);
  setRef(ref) {
    if (ref) {
      this.extraRef = ref;
    }
  }

  startsOrEndsWithWhitespace(str) {
    return str?.trim() !== str;
  }

  render() {
    const { limit, pillValue } = this.props;

    let value;
    let entries = [];

    if (this.props.value instanceof Immutable.Map) {
      value = this.props.value.entrySeq().toJS();
    } else if (Array.isArray(this.props.value)) {
      value = this.props.value;
    } else {
      value = Object.entries(this.props.value);
    }

    value.map(([k, v]) => {
      let text;
      let extra;

      if (typeof v === 'string') {
        text = v;
      } else {
        let isArray = Array.isArray(v);

        if (isArray) {
          text = v.slice(0, limit).join(', ');
          if (v.length > limit) {
            let more = v.slice(limit);

            if (more.length > 0) {
              extra = <span className={styles.wrapper} ref={this.setRef} onMouseOver={this.setDirection}>
                <AutoIntl
                  className={styles.extra}
                  displayId="au.entity.taps.more"
                  values={{ number: more.length }}
                  tag="div"
                />
                <span className={styles.inner_wrapper}>
                  <SearchableTooltip position={this.state.position}>
                    {more}
                  </SearchableTooltip>
                </span>
              </span>;
            }
          }
        }
      }

      if (pillValue) {
        entries.push(
          <div key={k} className={styles.container}>
            <div className={styles.keyvalue_container}>
              {!this.props.hideKey && <><AutoIntl className={styles.label} displayId={'au.taps.filter.details.annotation'} tag='div' />: </> }
              <div className={cn(styles.value, {[styles.remove_trim]: this.startsOrEndsWithWhitespace(text), [styles.value_margin]: !this.props.hideKey} )}>
                <AutoIntl displayId={k}/>
              </div>
            </div>
            <div className={styles.keyvalue_container}>
              {!this.props.hideKey && <AutoIntl className={cn(styles.label, styles.value_label)} displayString={formatMessage({id: 'au.taps.filter.details.values'}) + ':'} tag='div' /> }
              <div className={cn(styles.value, {[styles.remove_trim]: this.startsOrEndsWithWhitespace(text), [styles.value_margin]: !this.props.hideKey} )}>
                {v.map((val) => {
                  return <div className={styles.pill} key={val}><AutoIntl displayString={val}/></div>
                })}
              </div>
            </div>
            {this.startsOrEndsWithWhitespace(text) && <AutoIntl className={styles.hint} displayId='au.entity.taps.detailsHint' />}
          </div>
        );
      }
      else {
        entries.push(
          <div key={k} className={styles.container}>
            <div className={styles.keyvalue_container}>
              {!this.props.hideKey && <><AutoIntl className={styles.label} displayId={k} tag='div' />: </> }
              <div className={cn(styles.value, {[styles.remove_trim]: this.startsOrEndsWithWhitespace(text), [styles.value_margin]: !this.props.hideKey} )}>
                {text}{extra}
              </div>
            </div>
            {this.startsOrEndsWithWhitespace(text) && <AutoIntl className={styles.hint} displayId='au.entity.taps.detailsHint' />}
          </div>
        );
      }
    });

    return ( 
      <div className={styles.fancy_key_value}>
        {entries}
      </div> 
    );
  }
}
