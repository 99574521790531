import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import moment from 'moment-timezone';
import cn from 'classnames';

import TMC from '@autonomic/browser-sdk';
import AuButton, {
  BUTTON_TYPE_PRIMARY,
  BUTTON_TYPE_TERTIARY,
  BUTTON_SIZE_MEDIUM
} from '@au/core/lib/components/elements/AuButton';
import BinaryFontButton from '@au/core/lib/components/elements/BinaryFontButton';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';

import { NOOP, policyType, policyGroup } from '../../constants';
import { history as browserHistory } from '../../history';
import EntityLink from '../EntityLink';
import auFormatters from '../../utils/formatters';
import { formatMessage } from '../../utils/reactIntl';
import { findUserByUsername, findClientByName } from '../../utils/accounts';
import { validUuid } from '../../utils/validationRules';
import { getEntityUrlByArn, getParentEntityAwarePath } from '../../utils/entity';
import { loadPermissions, loadStatements, buildPolicies } from '../../utils/policies';
import { Statement, StatementTable } from './Statements';

import styles from '../../css/components/entity_policies.module.scss';

export class Policy extends React.Component {

  static propTypes = {
    data: IPT.map.isRequired,
    disableActions: T.bool,
    statementsOnly: T.bool,
    actions: T.shape({
      openEntityJsonViewer: T.func.isRequired
    }).isRequired,
    detailView: T.bool,
    expanded: T.bool,
    defaultExpand: T.bool,
    className: T.string
  }

  static defaultProps = {
    disableActions: false,
    statementsOnly: false,
    detailView: false,
    expanded: false,
    defaultExpand: false,
  }

  pkField = this.props.data.get('type') === policyType.SUBJECT
            ? 'subjectAui'
            : 'resourceAui';

  baseUrl = window.location.pathname.split('/').slice(0, -1).join('/');

  constructor(props) {
    super(props);

    this.userGroupEndpoint = new TMC.services.Accounts({apiVersion: '1'}).userGroups;

    this.state = { addedStatements: {}, statementsAdded: false, resources: {}, expand: this.props.expanded };
  }

  componentDidUpdate(prevProps) {
    const { data, expanded } = this.props;
    const statements = data.get('statements');
    const prevStatements = prevProps.data.get('statements');

    if (prevStatements.size && statements.size > prevStatements.size && prevStatements.size > 0) {
      this.setState({ addedStatements: statements.size - prevStatements.size, statementsAdded: true });
    }
    if(prevProps.expanded !== expanded) {
      this.setState({ expand: expanded });
    }

    if (this.state.statementsAdded) {
      this.timer = setTimeout(() => {
        this.setState({
          statementsAdded: false
        });
      }, 5000);
    } else {
      clearTimeout(this.timer);
    }
  }

  handleExpandClick = this.handleExpandClick.bind(this);
  handleExpandClick() {
    this.setState(prevState => ({ expand: !prevState.expand }));
  }

  onAuiMouseOver(e) {
    if (!e.target.findResource) {
      const { resource } = e.target.dataset;
      const clientName = resource.split('service-account-')[1];
      if (clientName) {
        e.target.findResource = findClientByName(clientName).then(res => {
          if (res.items.length) {
            return res;
          } else {
            return findUserByUsername(resource);
          }
        });
      } else {
        e.target.findResource = findUserByUsername(resource);
      }

      e.target.findResource = new Promise(resolve => {
        if (clientName) {
          return findClientByName(clientName).then(res => {
            if (res.items.length) {
              resolve(res);
            } else {
              findUserByUsername(resource).then(resolve);
            }
          });
        } else {
          return findUserByUsername(resource).then(resolve);
        }
      });
    }
  }

  onAuiMouseClick = this.onAuiMouseClick.bind(this);
  onAuiMouseClick(e) {
    e.persist();
    e.target.findResource.then(res => {
      const record = res.items[0];
      if (record) {
        let url = getEntityUrlByArn(e.target.dataset.arn, record.clientId ? 'client' : 'user');
        //FIXME - MOVE TO linkHelper
        browserHistory.push(`${url}/${record.id}/view`);
      }
    });
  }

  formatAui = this.formatAui.bind(this);
  formatAui(aui) {
    let [arn, resource] = aui.split(/\/(.+)/);
    let url;
    let uuid;
    let entityType;

    if (arn === 'aui:iam:user' && resource && resource.indexOf('*') === -1) {
      // we'll try to find user data on hover event to speed up the lookup.
      // NOTE `onMouseEnter` sets a Promise via `findResource` property that will
      // be used to handle `onClick`
      return (
        <a
          onMouseOver={this.onAuiMouseOver}
          onClick={this.onAuiMouseClick}
          data-resource={resource}
          data-arn={arn}
        >{ aui }</a>
      );
    } else {
      /*
        NOTE there are valid cases when resource will be `undefined`:
          - aui:*:*
          - aui:account:*
          - aui:asset:*
          - aui:asset:vehicle
          and many more...
       */
      uuid = resource;
    }

    if (uuid) {
      if (uuid.endsWith('/*')) {
        uuid = uuid.slice(0, -2);
      }
      if (validUuid(uuid) === null) {
        url = getEntityUrlByArn(arn, entityType);
      }
    }

    if (!url) {
      return aui;
    }

    return <EntityLink entityId={uuid} url={url}>{ aui }</EntityLink>;
  }

  renderExtraRows = this.renderExtraRows.bind(this);
  renderExtraRows(statement) {
    const rows = [];

    ['creatorAui', 'createTime'].forEach(field => {
      if (statement.get(field)) {
        rows.push(
          <tr key={`field_${statement.get('resourceAui')}_${field}`}>
            <td className={styles.fieldname}>
              <AutoIntl displayId={`au.policies.${field}`} />
            </td>
            <td className={styles.fieldvalue}>
              { field.endsWith('Time')
                ? auFormatters.date({
                  value: statement.get(field),
                  timezone: moment.tz.guess()
                })
                : this.formatAui(statement.get(field))
              }
            </td>
          </tr>
        );
      }
    });

    return rows;
  }

  renderPolicyView() {
    const { data } = this.props;
    const { expand, addedStatements, statementsAdded } = this.state;

    const attrName = this.pkField;
    const aui = data.get(attrName);
    const isDirectPolicy = data.get('group') === policyGroup.DIRECT;
    const statementAttrName = data.get('type') === policyType.SUBJECT ? 'resourceAui' : 'subjectAui';

    const rows = [];
    const statements = data.get('statements');
    statements.forEach(statement => {
      rows.push(
        <Statement key={`statement_${statement.get('_id')}`} statementAttrName={statementAttrName} statement={statement} isDirectPolicy={isDirectPolicy} formatAui={this.formatAui}/>
      );
    });

    return (
      <div className={styles.policy}>
        <div className={cn(styles.policy_group, styles.nested)}>
          <div className={styles.statement}>
            <BinaryFontButton
              onClick={this.handleExpandClick}
              onInitial={!expand}
              initial="collapse" next="minus-square" />
            <div className={styles.statement_toggle}>
              <AutoIntl displayId={`au.entity.attr.${attrName}`} className={styles.label} />
              <span>
                { isDirectPolicy && aui }
                { !isDirectPolicy && this.formatAui(aui) }
              </span>
            </div>
            {statementsAdded && <div className={styles.statements_added}>
              <AutoIntl displayId={`au.policies.statementsAdded`} values={{number: addedStatements}} />
            </div>
            }
          </div>
          {expand && <div className={styles.statements}>
            {rows}
          </div>}
        </div>
      </div>
    );
  }

  renderStatementView() {
    const { data, detailView, className } = this.props;
    const statements = [];

    const additionalProps = {
      showResourseAui: data.get('type') === policyType.SUBJECT,
      showSubjectAui: data.get('type') === policyType.RESOURCE,
    };

    if (detailView) {
      additionalProps.renderExtraRows = this.renderExtraRows;
      additionalProps.showResourseAui = true;
      additionalProps.showSubjectAui = true;
    }

    data.get('statements').forEach(statement => statements.push(
      <div key={`statement_${statement.get('_id')}`} className={styles.statement}>
        <StatementTable statement={statement} formatAui={this.formatAui} {...additionalProps} />
      </div>
    ));

    return <div className={cn(styles.policy, className)}>{ statements }</div>;
  }

  render() {
    return this.props.statementsOnly
           ? this.renderStatementView()
           : this.renderPolicyView();
  }

}

export default class Policies extends React.Component {

  static propTypes = {
    data: IPT.map,
    type: T.oneOf(Object.values(policyType)).isRequired,
    group: T.oneOf(Object.values(policyGroup)),
    subjectAui: T.string,
    resourceAui: T.string,
    parentEntity: T.object,
    statementsOnly: T.bool,
    actions: T.shape({
      listEntitiesSuccess: T.func.isRequired
    }).isRequired,
    onCreatePolicy: T.func,
    expanded: T.bool,
  }

  static defaultProps = {
    statementsOnly: false,
    onCreatePolicy: NOOP,
    expanded: false,
  }

  constructor(props) {
    super(props);

    this.state = { initialized: false, ready: false };

    this.pkField = props.type === policyType.SUBJECT
                   ? 'subjectAui'
                   : 'resourceAui';
  }

  componentDidMount() {
    this.initialLoad().then(() => this.setState({ initialized: true, ready: true }));
  }

  componentDidUpdate(prevProps) {
    const { initialized, ready } = this.state;

    if (prevProps.data.toJS() != this.props.data.toJS() && initialized && ready) { // hack to populate UserGroup -> Policies Subview
      this.initialLoad();                                                          // TODO: figure out why store is not being updated
    }
  }

  initialLoad() {
    const { type, group, parentEntity, actions } = this.props;

    // for the first load we can easily put everything to the store
    return this.getStatements(true).then(statements => {
      actions.listEntitiesSuccess({
        path: getParentEntityAwarePath(parentEntity, 'policies'),
        data: Object.values(buildPolicies(this.pkField, statements, group, type)),
        pkField: this.pkField
      });
    });
  }

  getStatements(initialLoad=false) {
    const { group, subjectAui, resourceAui, parentEntity } = this.props;
    let parentEntitySubjectAui = parentEntity && subjectAui == undefined ? parentEntity.entityDef.arn +  '/' + parentEntity.entityId : subjectAui;    // promises
    const inspect = [];

    if ((!group || group === policyGroup.DIRECT) && resourceAui) {
      if (initialLoad || this.nextPageTokenV1) {
        // statements based of v1 permissions
        inspect.push(loadPermissions(resourceAui, group, this.nextPageTokenV1).then(({ statements, nextPageToken }) => {
          this.nextPageTokenV1 = nextPageToken;
          return statements;
        }));
      }
    }

    if (initialLoad || this.nextPageTokenV2) {
      let params;
      if (resourceAui == parentEntitySubjectAui) {
        params = {resourceAui};
      } else {
        params = {subjectAui: parentEntitySubjectAui};
      }
      inspect.push(loadStatements(params, group, this.nextPageTokenV2).then(({ statements, nextPageToken }) => {
        this.nextPageTokenV2 = nextPageToken;
        return statements;
      }));
    }

    return Promise.all(inspect).then(results => {
      return [].concat(...results);
    });
  }

  loadMore  = this.loadMore.bind(this);
  loadMore() {
    const { type, group, parentEntity, actions } = this.props;

    this.setState({ ready: false });

    this.getStatements().then(statements => {
      const policies = buildPolicies(this.pkField, statements, group, type);
      /*
        NOTE
        This may look similar to `initialLoad`, but it is more exciting. We
        can't just put newly loaded statements from the next page to the store,
        we need to put them under the right policy record and update each
        respectively. IAM service doesn't provide a unique key for statements,
        that's why we need to keep track of our own `_id` field.
       */
      for (let [aui, policy] of Object.entries(policies)) {
        actions.listEntitiesSuccess({
          path: [...getParentEntityAwarePath(parentEntity, 'policies'), aui, 'statements'],
          data: Object.values(policy.statements),
          pkField: '_id'
        });
      }
    }).then(() => this.setState({ ready: true }));
  }

  renderPolicies() {
    const { data, group, statementsOnly, actions, expanded } = this.props;
    const { ready } = this.state;
    const policies = [];

    if (data.size) {
      data.forEach((policy, i) => {
        if (policy.get('subjectAui') || policy.get('resourceAui')) {
          policies.push(
            <Policy
              key={`policy_${i}`}
              data={policy}
              actions={actions}
              pkField={this.pkField}
              // disable actions if there is more data to fetch
              disableActions={Boolean(this.nextPageTokenV1 || this.nextPageTokenV2)}
              statementsOnly={statementsOnly}
              expanded={expanded}
            />
          );
        }
      });
      if (ready && (this.nextPageTokenV1 || this.nextPageTokenV2)) {
        let groupName = Object.entries(policyGroup).find(g => g[1] === group)[0];
        policies.push(
          <div key="load_more_statements" className={styles.load_more}>
            <AutoIntl
              displayId="au.policies.morePolicyStatementsAvailable"
              values={{
                policyGroup: formatMessage({ id: `au.policies.groups.${groupName}` })
              }}
              tag="div"
            />
            <AuButton
              key="load_more_statements_btn"
              type={BUTTON_TYPE_TERTIARY}
              size={BUTTON_SIZE_MEDIUM}
              displayId="au.policies.loadMore"
              className={styles.load_more_btn}
              onClick={this.loadMore}
            />
          </div>
        );
      }
    } else {
      policies.push(
        <div key="no_policies" className={styles.no_policies}>
          <AutoIntl
            className={styles.dimmed}
            displayId="au.policies.noPoliciesYet"
            tag="div"
          />
          { group === policyGroup.DIRECT &&
            <AuButton
              type={BUTTON_TYPE_PRIMARY}
              displayId="au.policies.createTargetPolicy"
              className={styles.create_policy_btn}
              onClick={this.props.onCreatePolicy}
            />
          }
        </div>
      );
    }

    return (
      <div className={styles.policies}>{ policies }</div>
    );
  }

  render() {
    const { ready, initialized } = this.state;

    return (
      <div className={styles.container}>
        { initialized && this.renderPolicies() }
        <LoadingIndicator className={styles.loader} display={!ready} />
      </div>
    );
  }

}
