import React from "react";
import * as T from "prop-types";

import { NOOP } from "@au/core/lib/constants";
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import TMC from "@autonomic/browser-sdk";
import AuDropDown from "@au/core/lib/components/elements/AuDropDown";
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';
import  { annotationFilterTypes } from '../constants';
import styles from '../css/components/annotation_tag_filter.module.scss';

export class AnnotationFilterKey extends React.PureComponent {
  // May contain other properties defined in AuDropDown.propTypes
  static propTypes = {
    value: T.string,
    onChange: T.func
  };

  static defaultProps = {
    onChange: NOOP,
    value: 'FTCP_MESSAGE_NAME'
  };

  state = {
    keyOptions: [],
    value: this.props.value
  }

  keyEndpoint = new TMC.services.Feed({apiVersion: 2}).annotationFilterTags;

  componentDidMount() {
    this.keyEndpoint.list().then(resp => {
      this.setState({
        keyOptions: resp.data.map((item) => ({
          displayString: item.key,
          val: item.key
        }))
      });
    }, createResponseAlertMessage);
  }

  render() {
    const { createMode, value, onChange, hintClassName,  ...otherProps } = this.props;
    const { keyOptions } = this.state;

    return (
      <div>
        <AuDropDown
          {...otherProps}
          selection={!Object.keys(annotationFilterTypes).includes(value) ? 'FTCP_MESSAGE_NAME' : value}
          selectionClassName={styles.selection}
          options={keyOptions}
          selectOption={onChange}
          createMode={!createMode}
          disabled={keyOptions.length <= 1}
          defaultValue={!Object.keys(annotationFilterTypes).includes(value) ? 'FTCP_MESSAGE_NAME' : value}
          className={styles.key_input}
        />
        {
          hintClassName && <div className={styles.key_hint_container}>
            <AutoIntl displayId={''} className={styles.icon} tag="div" />
            <AutoIntl displayId="au.annotationFilterKey.hint" className={hintClassName}/>
          </div>
        }
      </div>
    );
  }
}